.logo {
  float: left;
  height: 31px;
  margin: 16px 24px 16px 0;
}

.reportTags .reportTagsSelect .ant-select-selector {
  background-color: transparent !important;
  border: none !important;
}

.reportTags .reportTagsSelect .ant-select-selector:hover {
  border: none !important;
}

.reportTags {
  margin-bottom: 10px;
}
