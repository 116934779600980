@import url("devextreme/dist/css/dx.dark.css");
@import url("@devexpress/analytics-core/dist/css/dx-analytics.common.css");
@import url("@devexpress/analytics-core/dist/css/dx-analytics.dark.css");
@import url("devexpress-reporting/dist/css/dx-webdocumentviewer.css");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
